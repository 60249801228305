import "./App.css";
import Header from "./components/Header";
import Dua from "./components/Dua";
import React, { useState, useEffect } from "react";
import Settings from "./components/Settings";

import azkar_categories_translations from "./data/azkar_categories_translations.json";
import azkar_chapters from "./data/azkar_chapters.json";
import azkar_chapters_translations from "./data/azkar_chapters_translations.json";
import azkar_items from "./data/azkar_items.json";
import azkar_items_translations from "./data/azkar_items_translations.json";
import azkar_references_translations from "./data/azkar_references_translations.json";
import {
  Badge,
  Button,
  Card,
  CardContent,
  IconButton,
  TextField,
} from "@mui/material";
import { Brightness4, Brightness5Sharp } from "@mui/icons-material";

function App() {

 // const [language, setLanguage] = useState("ru");
  const [category, setCategory] = useState(0);
  const [chapter, setChapter] = useState(0);
  const [zikr, setZikr] = useState();
  const [showAll, setShowALl] = useState(false);
  const [search, setSearch] = useState("");






  const [language, setLanguage] = useState(localStorage.getItem('language')?localStorage.getItem('language'):'ru');

  useEffect(() => {
    localStorage.setItem('language', language);
   }, [language]);



  const [favorites, setFavorites] = useState(localStorage.getItem('favorites')?localStorage.getItem('favorites'):[74, 195, 6, 20]  );
  useEffect(() => {
   // setFavorites
    localStorage.setItem('favorites', favorites);
  }, [favorites]);


  const [showFavorites, setShowFavorites] = useState(true);

  const [onTransliteration, setOnTransliteration] = useState(true);
  const [onTranslation, setOnTranslation] = useState(true);
  const [onReference, setOnReference] = useState(false);
  const [onAudio, setonAudio] = useState(false);

  const zikrTranslation = azkar_items_translations.filter(
    (zikrTranslation) => zikrTranslation.language === language
  );
  const chapterTranslation = azkar_chapters_translations.filter(
    (chapterTranslation) => chapterTranslation.language === language
  );
  const categoryTranslation = azkar_categories_translations.filter(
    (categoryTranslation) => categoryTranslation.language === language
  );
  const referenceTranslation = azkar_references_translations.filter(
    (referenceTranslation) => referenceTranslation.language === language
  );

  const setCatEvent = (val) => {
    setCategory(val);
    setChapter(0);
    setZikr(0);
    setShowFavorites(0);
  };

  const setFavoriteShowValue = () => {
    setShowFavorites(true);
    setCategory(0);
    setZikr(0);
  };

  const saveToLocal = () => {
    return 0;
    localStorage.setItem("language", JSON.stringify(language));
  };

  const setSearchValue = (event) => {
    setShowFavorites(0);
    setSearch(event.target.value);
  };

  const searchResults = () => {
    return 0;
  };

  const setFavoriteValue = (el) => {
    console.log(33333)
    if (favorites.indexOf(el) === -1) {
      setFavorites((favs) => [...favs, el]);
    }
    if (favorites.indexOf(el) !== -1) {
      setFavorites(favorites.filter((item) => item !== el));
    }
  };

  const getChapterNameById = (id) => {
    return chapterTranslation.find(
      (el) => el.chapter_id === azkar_items[id - 1].chapter_id
    );
  };

  const getCategoryNameById = (id) => {
    return categoryTranslation.find(
      (el) =>
        el.category_id ===
        azkar_chapters[getChapterNameById(id).chapter_id - 1].category_id
    );
  };

  const setZikrValue = (el) => {
    return setZikr(el);
  };

  const ShowOneZikr = (el) => {
    return (
      <Dua
        key={zikr}
        number={zikr}
        textCategory={getCategoryNameById(zikr).category_name}
        textChapter={getChapterNameById(zikr).chapter_name}
        textArabic={azkar_items[zikr - 1].item}
        audioLink={onAudio ? azkar_items[zikr - 1].audio : ""}
        textTranslit={
          onTransliteration ? zikrTranslation[zikr - 1].item_translit : ""
        }
        textTranslation={
          onTranslation ? zikrTranslation[zikr - 1].item_translation : ""
        }
        reference={onReference ? referenceTranslation[zikr - 1].reference : ""}
        favoriteValue={setFavoriteValue}
        favoritesList={favorites ? favorites : ""}
        setZikr={setZikrValue}
      />
    );
  };

  const ShowFavorites = () => {
    return zikrTranslation
      .filter((item) => favorites.includes(item.item_id))
      .map((item) => (
        <Dua
          key={item.item_id}
          number={item.item_id}
          textCategory={getCategoryNameById(item.item_id).category_name}
          textChapter={getChapterNameById(item.item_id).chapter_name}
          textArabic={azkar_items[item.item_id - 1].item}
          audioLink={onAudio ? azkar_items[item.item_id - 1].audio : ""}
          textTranslit={onTransliteration ? item.item_translit : ""}
          textTranslation={onTranslation ? item.item_translation : ""}
          reference={
            onReference ? referenceTranslation[item.item_id - 1].reference : ""
          }
          favoriteValue={setFavoriteValue}
          favoritesList={favorites ? favorites : ""}
          setZikr={setZikrValue}
        />
      ));
  };

  const ShowSearchResults = () => {
    return zikrTranslation
      .filter(
        (item) =>
          search.length > 3 &&
          (item.item_translation.toLowerCase().includes(search.toLowerCase()) ||
            item.item_translit.toLowerCase().includes(search.toLowerCase()))
      )
      .map((item) => (
        <Dua
          key={item.item_id}
          number={item.item_id}
          textCategory={getCategoryNameById(item.item_id).category_name}
          textChapter={getChapterNameById(item.item_id).chapter_name}
          textArabic={azkar_items[item.item_id - 1].item}
          audioLink={onAudio ? azkar_items[item.item_id - 1].audio : ""}
          textTranslit={onTransliteration ? item.item_translit : ""}
          textTranslation={onTranslation ? item.item_translation : ""}
          reference={
            onReference ? referenceTranslation[item.item_id - 1].reference : ""
          }
          favoriteValue={setFavoriteValue}
          favoritesList={favorites ? favorites : ""}
          setZikr={setZikrValue}
        />
      ));
  };

  const ShowAll = () => {
    return azkar_items
      .filter(
        (item) =>
          azkar_chapters[item.chapter_id - 1].category_id === category &&
          (item.chapter_id === chapter || chapter === 0)
      )
      .map((item) => (
        <Dua
          key={item._id}
          number={item._id}
          textCategory={
            categoryTranslation.filter(
              (el) =>
                el.category_id ===
                azkar_chapters[item.chapter_id - 1].category_id
            )[0].category_name
          }
          textChapter={chapterTranslation[item.chapter_id - 1].chapter_name}
          textArabic={item.item}
          audioLink={onAudio ? item.audio : ""}
          textTranslit={
            onTransliteration ? zikrTranslation[item._id - 1].item_translit : ""
          }
          textTranslation={
            onTranslation ? zikrTranslation[item._id - 1].item_translation : ""
          }
          reference={
            onReference ? referenceTranslation[item._id - 1].reference : ""
          }
          favoriteValue={setFavoriteValue}
          favoritesList={favorites ? favorites : ""}
          setZikr={setZikrValue}
        />
      ));
  };

  const MainList = () => {
    if (showFavorites) return <ShowFavorites />;
    else if (search && !zikr) return <ShowSearchResults />;
    else if (zikr) return <ShowOneZikr />;
    else return <ShowAll />;
  };

  return (
    <div className="App">
      <div className="container">
        <Card className="mb-3  shadow-lg rounded" border="dark" bg="secondary">
          <CardContent>
            <Badge
              bg={language === "ru" ? "primary" : "secondary"}
              onClick={() => setLanguage("ru")}
            >
              Русский
            </Badge>{" "}
            <Badge
              bg={language === "en" ? "primary" : "secondary"}
              onClick={() => setLanguage("en")}
            >
              English
            </Badge>{" "}
            <Badge
              bg={language === "ar" ? "primary" : "secondary"}
              onClick={() => setLanguage("ar")}
            >
              عربي
            </Badge>{" "}
            <Settings
              onTranslation={onTranslation}
              onTransliteration={onTransliteration}
              onReference={onReference}
              onAudio={onAudio}
              setOnTransliterationHandler={setOnTransliteration}
              setOnTranslationHandler={setOnTranslation}
              setOnReferenceHandler={setOnReference}
              setOnAudioHandler={setonAudio}
            />
            <TextField
              value={search}
              onChange={setSearchValue}
              fullWidth
              label="Поиск по ключевым словам"
              id="fullWidth"
            />
            {search === "" ? (
              <blockquote className="blockquote mb-0">
                <ul>
                  <Button
                    variant={showFavorites ? "outlined" : "text"}
                    key={0}
                    onClick={() => setFavoriteShowValue(true)}
                  >
                    <Brightness4 /> Мои дуа
                  </Button>
                  {/*<Badge*/}
                  {/*  bg={category == 0 ? "primary" : "secondary"}*/}
                  {/*  pill*/}
                  {/*  key={0}*/}
                  {/*  onClick={() => setCategory(0)}*/}
                  {/*>*/}
                  {/*  {" "}*/}
                  {/*  Все{" "}*/}
                  {/*</Badge>*/}
                  {categoryTranslation.map((item) => (
                    <Button
                      variant={
                        item.category_id === category ? "outlined" : "text"
                      }
                      key={item.category_id}
                      onClick={() => setCatEvent(item.category_id)}
                    >
                      {item.category_name}{" "}
                    </Button>
                  ))}
                </ul>

                <ul>
                  {category === 0
                    ? ""
                    : chapterTranslation
                        .filter(
                          (item) =>
                            azkar_chapters[item.chapter_id - 1].category_id ===
                            category
                        )
                        .map((item) => (
                          <Button
                            variant={
                              item.chapter_id === chapter ? "outlined" : "text"
                            }
                            key={item.chapter_id}
                            onClick={() => setChapter(item.chapter_id)}
                          >
                            {item.chapter_name}{" "}
                          </Button>
                        ))}
                </ul>
              </blockquote>
            ) : (
              ""
            )}
          </CardContent>
        </Card>
        <MainList />
      </div>
    </div>
  );
}

export default App;
