import React, { useState } from "react";

import {
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  FormControlLabel,
  Switch,
  Button,
  FormGroup,
  Dialog,
  DialogTitle,
} from "@mui/material";
import { Settings as SettingsIcon } from "@mui/icons-material";

const Settings = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [onChange, setOnChange] = useState(true);

  const onChangeTransliteration = () => {
    props.setOnTransliterationHandler(!props.onTransliteration);
  };

  const onChangeTranslation = () => {
    props.setOnTranslationHandler(!props.onTranslation);
  };

  const onChangeReference = () => {
    props.setOnReferenceHandler(!props.onReference);
  };

  const onChangeAudio = () => {
    props.setOnAudioHandler(!props.onAudio);
  };

  const resetSettings = () => {
    props.setOnTransliterationHandler(true);
    props.setOnTranslationHandler(true);
    props.setOnReferenceHandler(false);
    props.setOnAudioHandler(false);
  };

  return (
    <>
      <IconButton aria-label="settings" component="span" onClick={handleShow}>
        <SettingsIcon />
      </IconButton>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={show}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Настройки
        </DialogTitle>

        <ToggleButtonGroup color="primary" value="android" exclusive>
          <ToggleButton value="web">English</ToggleButton>
          <ToggleButton value="android">Français</ToggleButton>
          <ToggleButton value="ios">Русский</ToggleButton>
          <ToggleButton value="ios">العربية</ToggleButton>
        </ToggleButtonGroup>
        <FormGroup>
          <FormControlLabel
            onChange={onChangeTransliteration}
            checked={props.onTransliteration}
            control={<Switch />}
            label="Отоброжать транслитерацию"
          />
          <FormControlLabel
            onChange={onChangeTranslation}
            checked={props.onTranslation}
            control={<Switch />}
            label="Отоброжать перевод"
          />
          <FormControlLabel
            onChange={onChangeReference}
            checked={props.onReference}
            control={<Switch />}
            label="Отображать источники"
          />
          <FormControlLabel
            onChange={onChangeAudio}
            checked={props.onAudio}
            control={<Switch />}
            label="Отоброжать аудио"
          />
        </FormGroup>
        <Button variant="text" onClick={resetSettings}>
          Сброс
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Закрыть
        </Button>
      </Dialog>
    </>
  );
};

export default Settings;
