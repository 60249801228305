import {
  Checkbox,
  Alert,
  Box,
  Card,
  CardContent,
  Grid,
  ButtonGroup,
  Snackbar,
  IconButton,
  Breadcrumbs,
  StyledBreadcrumb,
  Slider,
  Link,
  Paper,
} from "@mui/material";
import {
  Brightness5Sharp,
  PlayCircleOutline,
  IndeterminateCheckBox,
  AddBox,
  Share,
  InsertLink,
  ContentCopy,
  Brightness4,
} from "@mui/icons-material";

import "./Dua.css";
import { useState } from "react";

function Dua(props) {
  const favoriteValueHandler = (el) => {
    props.favoriteValue(el);
  };

  const [open, setOpen] = useState(false);
  const handleClickCopy = () => {
    setOpen(true);
    navigator.clipboard.writeText(
      props.textArabic +
        "\n\n" +
        props.textTranslit +
        "\n\n" +
        props.textTranslation
    );
  };

  // const [arabicSize, setArabicSize] = useState(42);
  //
  // const setArabicSizeHandler = (event) => {
  //   //setArabicSize(event.target.value);
  //   setArabicSize(arabicSize + 5);
  // };
  //
  // const setArabicSizeHandlerMinus = (event) => {
  //   //setArabicSize(event.target.value);
  //   if (arabicSize <= 35) {
  //     return 0;
  //   }
  //   setArabicSize(arabicSize - 5);
  // };

  return (
    <Card
      sx={{
        borderRadius: "3px",
        boxShadow: 0,
        m: 3,
        p: 1,
        bgcolor: "#fafafa",
      }}
    >
      <Box>
        {/*<Breadcrumbs separator={"/"} aria-label="breadcrumb">*/}
        <Box
          sx={{
            display: "flex",

            alignItems: "flex-end",
            border: 0,
          }}
        >
          <ButtonGroup
            aria-label="  outlined button group"
            sx={{
              border: 1,
              bgcolor: "#f0f0f0",
              borderColor: "white",
              alignContent: "flex-end",
            }}
          >
            <Checkbox
              sx={{ color: "#1976d2" }}
              icon={<Brightness5Sharp />}
              checkedIcon={<Brightness4 />}
              checked={
                props.favoritesList
                  ? props.favoritesList.includes(props.number)
                    ? true
                    : false
                  : ""
              }
              onClick={() => favoriteValueHandler(props.number)}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={handleClickCopy}
            >
              <ContentCopy />
              <Snackbar
                open={open}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Скопировано в буффер!"
              />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Share />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => props.setZikr(props.number)}
            >
              <InsertLink />
            </IconButton>
          </ButtonGroup>
        </Box>
        {/*<Link>{props.textCategory}</Link>*/}
        {/*<Link active="true">{props.textChapter}</Link>*/}
        {/*</Breadcrumbs>*/}
        {/*<Slider*/}
        {/*  aria-label="Small steps"*/}
        {/*  defaultValue={42}*/}
        {/*  min={10}*/}
        {/*  max={100}*/}
        {/*  valueLabelDisplay="auto"*/}
        {/*  onChange={setArabicSizeHandler}*/}
        {/*/>*/}
        <Paper
          elevation={0}
          sx={{
            fontSize: 22,
            p: 1,
            bgcolor: "#fafafa",
          }}
        >
          {props.textChapter}
        </Paper>
        {props.textArabic ? (
          <Paper
            elevation={0}
            sx={{
              fontSize: 42,
              fontFamily: "Traditional Arabic",
              color: "green",
              m: 1,
              p: 2,
              bgcolor: "#EDF7ED",
            }}
          >
            {/*{" "}*/}
            {/*<span onClick={setArabicSizeHandler}>*/}
            {/*  <AddBox />*/}
            {/*</span>*/}
            {/*<span onClick={setArabicSizeHandlerMinus}>*/}
            {/*  <IndeterminateCheckBox />*/}
            {/*</span>*/}
            {props.textArabic}
            <br />
            <audio controls loop>
              <source
                src="https://audio-samples.github.io/samples/mp3/blizzard_unconditional/sample-0.mp3"
                type="audio/mpeg"
              />
            </audio>{" "}
          </Paper>
        ) : (
          ""
        )}
        {props.textTranslit ? (
          <Paper
            elevation={0}
            sx={{
              m: 1,
              p: 2,
              bgcolor: "#e3f2fd",
            }}
          >
            {props.textTranslit}
          </Paper>
        ) : (
          ""
        )}
        {props.textTranslation ? (
          <Paper
            elevation={0}
            sx={{
              m: 1,
              p: 2,
              bgcolor: "#eeeeee",
            }}
          >
            {props.textTranslation}
          </Paper>
        ) : (
          ""
        )}
        {props.audioLink ? (
          <audio controls autoPlay>
            <source src={props.audioLink} type="audio/mpeg" />
          </audio>
        ) : (
          ""
        )}
        {props.reference ? (
          <Paper
            variant="outlined"
            sx={{
              m: 1,
              p: 2,
              fontStyle: "italic",
              bgcolor: "#fafafa",
            }}
          >
            {props.reference}
          </Paper>
        ) : (
          ""
        )}
      </Box>
    </Card>
  );
}

export default Dua;
